<template>
  <v-app-bar app flat color="surface" :density="density">
    <v-container class="fill-height pa-0">
      <v-row no-gutters style="height: 100%; max-height: 100%">
        <v-col cols="4" style="height: 100%; max-height: 100%">
          <slot name="left"></slot>
        </v-col>
        <v-col
          cols="4"
          style="max-height: 100%; display: flex; justify-content: center; align-items: center"
          :class="logoPaddingClass"
        >
          <v-responsive
            :ratio="500 / 71"
            style="max-height: 100%; max-width: 100%"
            class="text-center"
          >
            <img
              src="~/assets/logo.svg"
              :alt="
                $t('meta.title', {
                  brand: cnfg.brand,
                })
              "
              style="max-height: 100%; max-width: 100%"
            />
          </v-responsive>
        </v-col>
        <v-col cols="4" style="height: 100%; max-height: 100%">
          <slot name="right"></slot>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useDisplay } from 'vuetify'

export default defineComponent({
  name: 'PartialsHeader',
  setup() {
    const runtimeConfig = useRuntimeConfig()
    const display = useDisplay()
    const smAndDown = computed(() => display.smAndDown.value)
    const density = computed(() => (smAndDown.value ? 'compact' : 'default'))
    const logoPaddingClass = computed(() => (smAndDown.value ? 'py-1' : 'py-3'))
    return {
      cnfg: runtimeConfig.public,
      density,
      logoPaddingClass,
    }
  },
})
</script>
